import { assign, merge } from 'lodash'
import * as api from '@/api'
import { TASK_POLLING_DELAY, PROCESS_POLLING_DELAY } from '@/config'
import { errorParser, removeEmptyStrings } from '@/helpers'
import { useJobsStore, useWorkerStore } from '@/stores'

export const initialState = () => ({
  // { [processId]: { [workerRunId]: { workerRun } }
  processWorkerRuns: {},
  // { [processID]: processDetails }
  processes: {},
  // { number, next, results: processId[] }
  processPage: {},
  // { [processId]: ElementsPaginatedResponse }
  processElementsPage: {},
  /**
   * ProcessSets by process ID.
   * @type {{ [processId: string]: import('@/types').ProcessSet[] }}
   */
  processSets: {},
  /**
   * Tasks for the current process, with an added `timeoutId` property for task polling
   */
  tasks: {},
  // { [taskId]: TaskArtifacts }
  artifacts: {},
  // ID of the process getting polled, null if it is turned off
  pollingProcessId: null,
  // Timeout ID for the process polling, null if it is turned off
  processTimeoutId: null
})

export const mutations = {

  setProcessWorkerRuns (state, { processId, workerRuns }) {
    if (!state.processWorkerRuns[processId]) state.processWorkerRuns[processId] = {}
    state.processWorkerRuns = {
      ...state.processWorkerRuns,
      [processId]: {
        ...state.processWorkerRuns[processId],
        ...Object.fromEntries(workerRuns.map(workerRun => [workerRun.id, workerRun]))
      }
    }
  },

  removeProcessWorkerRun (state, { processId, workerRunId }) {
    const runs = state.processWorkerRuns[processId]
    if (!runs || !runs[workerRunId]) return
    delete runs[workerRunId]
    Object.values(runs).forEach(workerRun => {
      workerRun.parents = workerRun.parents.filter(parent => parent !== workerRunId)
    })
  },

  /**
   * Remove all worker runs of given process
   */
  removeProcessWorkerRuns (state, { processId }) {
    state.processWorkerRuns[processId] = {}
  },

  setProcess (state, process) {
    const existingProcess = state.processes[process.id] ?? {}
    const newProcess = {
      ...existingProcess,
      ...process
    }
    /**
     * Only mark a process as "complete" if we have the fields that are only found in RetrieveProcess and nowhere else
     * or if the existing process was already complete
     */
    newProcess._complete = existingProcess._complete || ('farm' in newProcess && Array.isArray(newProcess.tasks))

    if (Array.isArray(process.tasks)) {
      const newIds = process.tasks.map(task => task.id)

      // Take all timeout IDs of tasks that will be deleted, and stop polling them
      Object.values(state.tasks)
        .filter(task => !newIds.includes(task.id) && task.timeoutId !== null)
        .forEach(task => clearTimeout(task.timeoutId))

      state.tasks = Object.fromEntries(process.tasks.map(task => [task.id, {
        // Default value for the timeoutId
        timeoutId: null,
        // Previously stored task, if it exists
        ...(state.tasks[task.id] ?? {}),
        // The new task
        ...task,
        // The task's process, which is returned by RetrieveTask but not by RetrieveProcess
        process_id: process.id
      }]))

      // Do not duplicate the process' tasks within state.processes and state.tasks
      delete newProcess.tasks
    }

    state.processes = {
      ...state.processes,
      [newProcess.id]: newProcess
    }
  },

  setProcesses (state, processes) {
    /**
     * Set a list of processes. The `_complete` attribute is set to false as
     * a slim serializer is used when listing processes
     */
    state.processes = {
      ...state.processes,
      ...processes.reduce((obj, process) => {
        obj[process.id] = { ...merge({ _complete: false }, state.processes[process.id] || {}, process) }
        return obj
      }, {})
    }
  },

  setProcessPage (state, page) {
    state.processPage = {
      ...state.processPage,
      ...page,
      results: page.results.map(process => process.id)
    }
  },

  removeProcess (state, processId) {
    delete state.processes[processId]
  },

  setProcessElementsPage (state, { processId, response }) {
    if (!Number.isInteger(response.count)) delete response.count
    const newResponse = { ...state.processElementsPage[processId], ...response }
    state.processElementsPage = { ...state.processElementsPage, [processId]: newResponse }
  },

  setProcessSets (state, { processId, results }) {
    const processSetList = state.processSets[processId] || []
    results.forEach(newProcessSet => {
      // Prevent duplicating datasets
      if (!processSetList.some(processSet => processSet.id === newProcessSet.id)) processSetList.push(newProcessSet)
    })
    // Merge process datasets
    state.processSets = {
      ...state.processSets,
      [processId]: processSetList
    }
  },

  removeProcessSet (state, { processId, processSetId }) {
    const processSetList = state.processSets[processId] || []
    const index = processSetList.findIndex(({ id }) => id === processSetId)
    if (index < 0) return
    processSetList.splice(index, 1)
    state.processSets = {
      ...state.processSets,
      [processId]: processSetList
    }
  },

  setPollingProcessId (state, id) {
    state.pollingProcessId = id
  },

  setProcessTimeoutId (state, id) {
    state.processTimeoutId = id
  },

  setTask (state, task) {
    state.tasks[task.id] = {
      timeoutId: null,
      ...(state.tasks[task.id] || {}),
      ...task
    }
  },

  setTaskTimeoutId (state, { taskId, timeoutId }) {
    if (!state.tasks[taskId]) throw new Error(`Unknown task ${taskId}`)
    state.tasks[taskId].timeoutId = timeoutId
  },

  setTaskArtifacts (state, { taskId, artifacts }) {
    state.artifacts = { ...state.artifacts, [taskId]: artifacts }
  },

  stopPolling (state) {
    // Stop the process polling
    state.pollingProcessId = null
    if (state.processTimeoutId !== null) {
      clearTimeout(state.processTimeoutId)
      state.processTimeoutId = null
    }

    // Stop all task pollings
    state.tasks = Object.fromEntries(
      Object.entries(state.tasks).map(([id, task]) => {
        if (task.timeoutId !== null) {
          clearTimeout(task.timeoutId)
          task.timeoutId = null
        }
        return [id, task]
      })
    )
  },

  stopTaskPolling (state, id) {
    if (!state.tasks[id] || state.tasks[id].timeoutId === null) return

    clearTimeout(state.tasks[id].timeoutId)
    state.tasks = {
      ...state.tasks,
      [id]: {
        ...state.tasks[id],
        timeoutId: null
      }
    }
  },

  reset (state) {
    assign(state, initialState())
  }
}

export const actions = {
  async listWorkerRuns ({ commit, dispatch }, { processId, page = 1 }) {
    try {
      // Automatically list all worker runs for a process through infinite pagination
      const data = await api.listWorkerRuns({ id: processId, page })
      commit('setProcessWorkerRuns', { processId, workerRuns: data.results })
      // Add configurations to workerConfigurations store
      const configurationsStore = useWorkerStore().workerConfigurations
      data.results.forEach(
        workerRun => {
          if (!(workerRun.configuration)) return
          if (!(workerRun.worker_version.worker.id in configurationsStore)) configurationsStore[workerRun.worker_version.worker.id] = {}
          configurationsStore[workerRun.worker_version.worker.id][workerRun.configuration.id] = workerRun.configuration
        }
      )
      if (!data || !data.number || page !== data.number) {
        // Avoid any loop
        throw new Error(`Pagination failed listing worker runs for process "${processId}"`)
      }
      // Load other pages
      if (data.next) dispatch('listWorkerRuns', { processId, page: page + 1 })
    } catch (err) {
      commit('setProcessWorkerRuns', { processId, workerRuns: [] })
      throw err
    }
  },

  async createWorkerRun ({ commit }, { processId, workerRun }) {
    const data = await api.createWorkerRun({ id: processId, workerRun })
    commit('setProcessWorkerRuns', { processId, workerRuns: [data] })
  },

  async updateWorkerRun ({ commit }, { processId, workerRunId, payload }) {
    const data = await api.updateWorkerRun({ id: workerRunId, payload })
    commit('setProcessWorkerRuns', { processId, workerRuns: [data] })
  },

  async deleteWorkerRun ({ commit }, { processId, workerRunId }) {
    await api.deleteWorkerRun(workerRunId)
    commit('removeProcessWorkerRun', { processId, workerRunId })
  },

  async clearProcess ({ commit }, { processId }) {
    await api.clearProcess(processId)
    commit('removeProcessWorkerRuns', { processId })
  },

  async listProcesses ({ commit }, params) {
    try {
      const data = await api.listProcesses(removeEmptyStrings(params))
      commit('setProcesses', data.results)
      commit('setProcessPage', data)
    } catch (err) {
      commit('setProcessPage', { results: [] })
      throw err
    }
  },

  async listTemplates ({ commit }, { page = 1, name = '' } = {}) {
    const data = await api.listTemplates({ mode: 'template', page, name })
    commit('setProcesses', data.results)
    commit('setProcessPage', data)
    return data
  },

  async createProcess ({ commit }, payload) {
    const data = await api.createProcess(payload)
    commit('setProcess', data)
    return data
  },

  async createProcessTemplate ({ commit }, { processId, payload }) {
    const data = await api.createProcessTemplate({ id: processId, payload })
    commit('setProcess', data)
    return data
  },

  async applyProcessTemplate ({ commit }, { templateId, payload }) {
    const data = await api.applyProcessTemplate({ id: templateId, payload })
    commit('removeProcessWorkerRuns', { processId: payload.process_id })
    commit('setProcess', data)
    return data
  },

  async updateProcess ({ commit }, { processId, payload }) {
    const data = await api.updateProcess({ id: processId, payload })
    commit('setProcess', data)
    return data
  },

  async startProcess ({ commit }, { processId, payload }) {
    const data = await api.startProcess({ id: processId, payload })
    commit('setProcess', data)
    // The WorkerActivity initialization RQ task is now visible to the user
    useJobsStore().list()
  },

  async retrieveProcess ({ commit }, processId) {
    const data = await api.retrieveProcess(processId)
    commit('setProcess', data)
  },

  async deleteProcess ({ commit }, processId) {
    const response = await api.deleteProcess(processId)
    if (response.status === 202) {
      commit(
        'notifications/notify',
        { type: 'success', text: `Deletion of process ${processId} has been recorded and will be performed soon.`, timeout: 10000 },
        { root: true }
      )
    } else {
      commit('removeProcess', processId)
    }
    return response
  },

  async retryProcess ({ commit }, processId) {
    const data = await api.retryProcess(processId)
    commit('setProcess', data)
    // The WorkerActivity initialization RQ task is now visible to the user
    useJobsStore().list()
  },

  async listProcessElements ({ state, commit }, { processId, cursor = '' }) {
    // Handle url requests for cursor pagination
    const payload = { id: processId, cursor }
    // Automatically fetch elements count if needed
    const processEltsPage = state.processElementsPage[processId]
    if (!processEltsPage || processEltsPage.count == null || !cursor) payload.with_count = true
    const response = await api.listProcessElements(payload)
    // Override the process loaded page
    commit('setProcessElementsPage', { processId, response })
  },

  async listProcessSets ({ state, commit, dispatch }, { processId, page = 1 }) {
    // Do not start fetching process datasets if they have been retrieved already
    if (page === 1 && state.processSets[processId]) return

    let data = null
    try {
      data = await api.listProcessSets({ processId, page })
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
      throw err
    }

    if (!data || !data.number || page !== data.number) {
      // Avoid any loop
      throw new Error(`Pagination failed while listing sets for process "${processId}"`)
    }

    commit('setProcessSets', { processId, results: data.results })

    // Load other pages
    if (data.next) await dispatch('listProcessSets', { processId, page: page + 1 })
  },

  async createProcessSet ({ commit }, { processId, setId }) {
    // Errors are handled in components/Process/Datasets/AddForm.vue
    const resp = await api.createProcessSet(processId, setId)
    commit('setProcessSets', { processId, results: [resp.data] })
  },

  async deleteProcessSet ({ commit }, { processId, processSetId, setId }) {
    try {
      await api.deleteProcessSet(processId, setId)
      commit('removeProcessSet', { processId, processSetId })
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
      throw err
    }
  },

  async updateTask ({ commit }, { id, ...payload }) {
    try {
      const task = await api.updateTask(id, payload)
      commit('setTask', task)
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
    }
  },

  async restartTask ({ state, commit }, id) {
    const processId = state.tasks[id]?.process_id
    const newTask = await api.restartTask(id)
    commit('setTask', {
      ...newTask,
      process_id: processId
    })
  },

  async getTaskArtifacts ({ commit }, id) {
    try {
      const data = await api.listArtifacts(id)
      commit('setTaskArtifacts', { taskId: id, artifacts: data })
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
    }
  },

  async fromFiles ({ commit }, payload) {
    try {
      const data = await api.importFromFiles(payload)
      return data
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
      throw err
    }
  },

  async stop ({ commit }, { id }) {
    const data = await api.updateProcess({ id, payload: { state: 'stopping' } })
    commit('setProcess', data)
  },

  startPolling (store, id) {
    store.commit('stopPolling')
    store.commit('setPollingProcessId', id)
    const poll = async () => {
      // Polling has been stopped or is running on another process
      if (!store.state.processTimeoutId || store.state.pollingProcessId !== id) return

      try {
        await store.dispatch('retrieveProcess', id)
      } catch (err) {
        store.commit('notifications/notify', { type: 'error', text: `Error while fetching process: ${errorParser(err)}` }, { root: true })

        // Abort polling on HTTP 4xx
        if (err.response?.status >= 400 && err.response?.status < 500) {
          store.commit('stopPolling')
          return
        }
      }

      // Check again, because the polling might have been stopped while we were awaiting the HTTP request.
      if (!store.state.processTimeoutId || store.state.pollingProcessId !== id) return

      store.commit('setProcessTimeoutId', setTimeout(poll, PROCESS_POLLING_DELAY))
    }

    // Make the first call; poll cannot be called directly due to the initial timeout ID check
    store.commit('setProcessTimeoutId', setTimeout(poll, 0))
  },

  startTaskPolling (store, id) {
    store.commit('stopTaskPolling', id)
    const poll = async () => {
      const task = store.state.tasks[id]
      // Polling has been stopped, process has changed or task was deleted
      if (!task || !task.timeoutId) return

      try {
        store.commit('setTask', await api.retrieveTask(id))
      } catch (err) {
        store.commit('notifications/notify', { type: 'error', text: `Error while fetching task ${task.slug}: ${errorParser(err)}` }, { root: true })

        // Abort polling on HTTP 4xx
        if (err.response.status && err.response.status >= 400 && err.response.status < 500) {
          store.commit('stopTaskPolling', id)
          return
        }
      }

      // Check again, because the polling might have been stopped while we were awaiting the HTTP request.
      if (!store.state.tasks[id]?.timeoutId) return

      store.commit('setTaskTimeoutId', {
        taskId: id,
        timeoutId: setTimeout(poll, TASK_POLLING_DELAY)
      })
    }

    // Make the first call; poll cannot be called directly due to the initial timeout ID check
    store.commit('setTaskTimeoutId', {
      taskId: id,
      timeoutId: setTimeout(poll, 0)
    })
  },

  async selectFailures ({ dispatch, commit }, processId) {
    try {
      await api.selectProcessFailures(processId)
      dispatch('selection/get', {}, { root: true })
      commit('notifications/notify', { type: 'success', text: 'Elements with failures have been added to your selection' }, { root: true })
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
    }
  }
}

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  actions
}
